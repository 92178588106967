import React, { Component } from "react";

// eslint-disable-next-line
import $ from 'jquery';

// eslint-disable-next-line
import {
  NavLink
} from "react-router-dom";

class Home extends Component {

  render() {

    return (
      <div className="page" id="pageHome">

				<div className="content">
					<div className="row align-middle">
						<div className="columns large-7 medium-8">
							<h1>Season's Greetings</h1>
							<h2>From all of the team at Veolia Water Technologies.</h2>
							<div className="box blue">
								<p>We have reached our target of 500 games and will be donating <strong>£1000 to the UK SEPSIS Trust</strong>, thanks for playing.</p>
								<p>You can still have a go at our game by completing the sentences to reveal the whole puzzle picture.</p>
							</div>
							<NavLink className="button" exact to="/Video">Play</NavLink>
						</div>
					</div>
				</div>
      </div>
    );
  }
}

export default Home;
