import React, { Component } from "react";

import {
  Route,
  BrowserRouter,
  Switch
} from "react-router-dom";

import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group';

import Snowfall from 'react-snowfall'

import TagManager from 'react-gtm-module';

import Header from "./Header";
import Footer from "./Footer";

import Home from "./Home";
import Video from "./Video";
import One from "./One";
import Two from "./Two";
import Three from "./Three";
import Complete from "./Complete";
import Picture from "./Picture";

const tagManagerArgs = {
    gtmId: 'GTM-WFRTWHB'
}

TagManager.initialize(tagManagerArgs);

class Main extends Component {

  render() {

    return  (
	    <BrowserRouter basename="/">
	    	<div className="outerWrap">
        <div className="wrapper">
						<Snowfall snowflakeCount={300} />
						<Header />
	          <Route
		          render={({ location }) => {
		            const { pathname } = location;
		            return (
		              <TransitionGroup>
		                <CSSTransition
		                  key={pathname}
		                  classNames="page"
		                  timeout={{
		                    enter: 1000,
		                    exit: 1000,
		                  }}
		                >
		                  <Route
		                    location={location}
		                    render={() => (
		                      <Switch>
								            <Route exact path="/" component={Home}/>
														<Route path="/Video" component={Video}/>
														<Route path="/One" component={One}/>
														<Route path="/Two" component={Two}/>
														<Route path="/Three" component={Three}/>
														<Route path="/Complete" component={Complete}/>
														<Route path="/Picture" component={Picture}/>
		                      </Switch>
		                    )}
		                  />
		                </CSSTransition>
		              </TransitionGroup>
		            );
		          }}
		        />
						<Footer />
        </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default Main;
