import React, { Component } from "react";

import {
	NavLink
} from "react-router-dom";

//import Share from "./Share";

const ball = require('./assets/svg/2.svg');
const correct = require('./assets/svg/correct.svg');

class Door extends Component {

	state = {hasError:true};

	onChange(event){
		if(event.target.value.match("forward-thinking technology")){
			this.setState({hasError:false});
		}else{
			this.setState({hasError:true});
		}
	}

  render() {
    return (
      <div className="page question" id="pageTwo" ref={el => (this.div = el)}>
      	<div className="content">
					<div className="row align-middle justify-center collapse">
						<div className="shrink columns column-block text-center">
							<img className="ball" src={ball} alt="2" />
						</div>
						<div className="shrink columns column-block text-center">
							<h1>Complete the sentence</h1>
						</div>
					</div>
					<div className="row align-center">
						<div className="auto columns text-center">
							<p>The product selector tool is helping to make customers’ day-to-day operations easier, much like the <input autoComplete="off" name="q2p1" onChange={this.onChange.bind(this)}/> it supports; the PURELAB® Chorus Lab Water range.</p>
							<p><a href="https://blog.veoliawatertechnologies.co.uk/in-chorus-with-vwt" target="_blank" rel="noopener noreferrer">Visit the VWT Blog for the answer</a></p>
						</div>
					</div>
				</div>
				<div className="buttons">
					{!this.state.hasError && <NavLink className="correct" exact to="/Three"><img src={correct} width="150" alt="correct" /></NavLink>}
				</div>
      </div>
    );
  }
}

export default Door;
