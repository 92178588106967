import React, { Component } from "react";

// eslint-disable-next-line
import $ from 'jquery';

// eslint-disable-next-line
import {
  NavLink
} from "react-router-dom";

class Video extends Component {

  render() {

    return (
      <div className="page" id="pageVideo">

				<div className="content">
					<div className="row align-middle justify-center">
						<div className="columns large-10 text-center">
							<div className="video-responsive">
								<iframe width="640" height="480" src="https://www.youtube.com/embed/fsluLVFIsq4?rel=0&autoplay=1" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="xmas"></iframe>
							</div>
							<NavLink className="button" exact to="/One">Play Game</NavLink>
						</div>
					</div>
				</div>
      </div>
    );
  }
}

export default Video;
