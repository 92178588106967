import React, { Component } from "react";
import Firebase from "firebase";
import config from "./config";

import {
  NavLink
} from "react-router-dom";

const waterTech = require('./assets/svg/water-tech.svg');

class Header extends Component {

	constructor(props) {
		super(props);
		Firebase.initializeApp(config);

		this.state = {
			developers: []
		};
	}

	componentDidMount() {
		this.getUserData();
	}

	getUserData = () => {
		let ref = Firebase.database().ref("/");
		ref.on("value", snapshot => {
			const state = snapshot.val();
			this.setState(state);
		});
	};

  render() {

		const { developers } = this.state;
		const numRows = developers.length;
		const numRowsDouble = developers.length*2;

    return (

      <header>
      	<NavLink exact to="/"><img className="waterTech" src={waterTech} alt="Water Technologies" width="307" /></NavLink>
				<div className="count">
					<h4>Games Complete <span className="number">500 / 500</span></h4>
					<h4>Target <span className="number">£1000 / £1000</span></h4>
				</div>
	    </header>
    );
  }
}

export default Header;
