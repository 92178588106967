import React, { Component } from "react";

// eslint-disable-next-line
import $ from 'jquery';

// eslint-disable-next-line
import {
  NavLink
} from "react-router-dom";

class Home extends Component {

  render() {

    return (
      <div className="page" id="pagePicture">

				<div className="content">
					<div className="row align-top justify-center">
						<div className="large-12 columns text-center">
							<h1>Season's Greetings</h1>
							<h2>From all of the team at Veolia Water Technologies.</h2>
							<NavLink className="button" exact to="/">Home</NavLink>
						</div>
					</div>
				</div>
      </div>
    );
  }
}

export default Home;
