import React, { Component } from "react";

import {
	NavLink
} from "react-router-dom";

//import Share from "./Share";

const ball = require('./assets/svg/3.svg');
const correct = require('./assets/svg/correct.svg');

class Door extends Component {

	state = {hasError:true};

	onChange(event){
		if(event.target.value.match("technical knowledge")){
			this.setState({hasError:false});
		}else{
			this.setState({hasError:true});
		}
	}

  render() {
    return (
      <div className="page question" id="pageThree" ref={el => (this.div = el)}>
      	<div className="content">
					<div className="row align-middle justify-center collapse">
						<div className="shrink columns column-block text-center">
							<img className="ball" src={ball} alt="2" />
						</div>
						<div className="shrink columns column-block text-center">
							<h1>Complete the sentence</h1>
						</div>
					</div>
					<div className="row align-center">
						<div className="auto columns text-center">
							<p>Our team is on-hand to provide clients with support, sharing its <input autocomplete="off" name="q3p1" onChange={this.onChange.bind(this)}/> and years of experience.</p>
							<p><a href="https://blog.veoliawatertechnologies.co.uk/water-treatment-chemicals-added-value" target="_blank" rel="noopener noreferrer">Visit the VWT for the answer</a></p>
						</div>
					</div>
				</div>
				<div className="buttons">
					{!this.state.hasError && <NavLink className="correct" exact to="/Complete"><img src={correct} width="150" alt="correct" /></NavLink>}
				</div>
      </div>
    );
  }
}

export default Door;
