import React, { Component } from "react";

import Firebase from "firebase";

// eslint-disable-next-line
import $ from 'jquery';

class Home extends Component {

	componentDidMount() {
		this.getUserData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState !== this.state) {
			this.writeUserData();
		}
	}

	writeUserData = () => {
		Firebase.database()
			.ref("/")
			.set(this.state);
		console.log("DATA SAVED");
	};

	getUserData = () => {
		let ref = Firebase.database().ref("/");
		ref.on("value", snapshot => {
			const state = snapshot.val();
			this.setState(state);
		});
	};

	handleSubmit = event => {
		event.preventDefault();
		let name = this.refs.name.value;
		let uid = this.refs.uid.value;

		if (uid && name) {
			const { developers } = this.state;
			const devIndex = developers.findIndex(data => {
				return data.uid === uid;
			});
			developers[devIndex].name = name;
			this.setState({ developers });
		} else if (name) {
			const uid = new Date().getTime().toString();
			const { developers } = this.state;
			developers.push({ uid, name });
			this.setState({ developers });
		}

		this.refs.name.value = "";
		this.refs.uid.value = "";
		this.props.history.push('/Picture');
	};

	removeData = developer => {
		const { developers } = this.state;
		const newState = developers.filter(data => {
			return data.uid !== developer.uid;
		});
		this.setState({ developers: newState });
	};

	updateData = developer => {
		this.refs.uid.value = developer.uid;
		this.refs.name.value = developer.name;
	};

  render() {

    return (
      <div className="page question" id="pageComplete">

				<div className="content">
					<div className="row align-middle justify-center">
						<div className="large-10 columns text-center">
							<h1>Well Done</h1>
							<p><strong>Click the button</strong> below to add another <strong>£2</strong> towards our target charity donation and reveal the full picture.<strong> Thank you!</strong></p>
							<form onSubmit={this.handleSubmit}>
								<div className="form-row">
									<input type="hidden" ref="uid" />
									<div className="form-group col-md-6">
										<input
											type="hidden"
											ref="name"
											className="form-control"
											placeholder="Name"
											value="Player"
										/>
									</div>
								</div>
								<button className="button" type="submit">Complete Game</button>
							</form>
						</div>
					</div>
				</div>
      </div>
    );
  }
}

export default Home;
